body {
  margin: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box; /* new line */
}

* {
  box-sizing: inherit; /* new line */
}

.container {
  padding: 0px;
  box-sizing: border-box; /* new line */
  background-color: transparent;
}

.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000; /* Set background color to black */

  z-index: 998; /* Adjust z-index to be behind the nav bar */
}

.intro {
  height: 100vh; /* This will take the full height of the view port */
  width: 100vw; /* This will take the full width of the view port */
  position: absolute; /* This will make it positioned relative to the browser window */

  background-color: rgb(0, 0, 0);
}

.get-started {
  /* This will take the full height of the view port */
  width: 100vw; /* This will take the full width of the view port */
  position: absolute;

  background-color: #000;
  min-height: 100vh;
}

.logo {
  position: fixed;
  top: -12px;
  left: 25px;
  height: auto; /* Remove fixed height */
  width: 120px; /* Adjust the width as needed */
  z-index: 999;
}

.our-services {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Assuming you want the section to take up the full viewport height */
  z-index: 9;
}

.services-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-size: 2rem; /* Optional: Adjust the font size or any other style as needed */
}
/* Mouse Field styles */
.mouse-field-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.mouse-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mouse {
  width: 30px;
  height: 60px;
  border: 2px solid #f1f1f1;
  border-radius: 30px;
  position: relative;
  bottom: 30px;
}

.mouse::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f1f1f1;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
}

/* Nav styles */
.nav-container {
  position: fixed;
  top: 25px;
  left: 0;
  width: 100%;
  background-color: transparent;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.nav-item {
  margin: 0 20px;
  cursor: pointer;
  color: white;
  font-size: 40px; /* Adjust the font size as needed */
  position: relative;
  transition: color 0.3s ease; /* Smooth transition animation for text color */
}

/* Styles for non-active sections when hovered */
.nav-item:hover {
  color: #5bc0de; /* Change color on hover */
}

/* Styles for the active section */
.nav-item.active {
  color: #8a4fff; /* Text color for active section */
  position: relative; /* Set position to use for pseudo-elements */
}

/* Create an animated underline for both active and non-active sections */
.nav-item.active::before,
.nav-item:hover::before {
  content: "";
  position: absolute;
  bottom: -2px; /* Adjust the distance from the text */
  left: 0;
  width: 100%;
  height: 2px; /* Border thickness */
  background-image: linear-gradient(
    45deg,
    #8a4fff,
    #8a4fff,
    #5bc0de,
    #5bc0de,
    #8a4fff,
    #8a4fff
  ); /* Gradient pattern of colors */
  background-size: 300% 300%; /* Triple the size for animation */
  animation: gradientAnimation 3s linear infinite; /* Animation for the gradient */
}
/* Styling for Introduction navigation item */
.introduction-nav-item {
  font-size: 20px; /* Set the font size to 20px */
  /* Add any other styles you want here */
}

/* Styling for Our Services navigation item */
.ourservices-nav-item {
  font-size: 20px; /* Set the font size to 20px */
  /* Add any other styles you want here */
}

/* Styling for Get Started navigation item */
.getstarted-nav-item {
  font-size: 20px; /* Set the font size to 20px */
  /* Add any other styles you want here */
}

/* Keyframes animation for the gradient pattern */
@keyframes gradientAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 300% 300%;
  }
}

.scrollbar-container {
  position: absolute;
  height: 4px;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.scrollbar-background {
  position: absolute;
  height: 4px;
  background-color: white;
  opacity: 0.3;
  bottom: -10px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.scrollbar {
  position: absolute;
  height: 4px;
  background-color: white;
  bottom: -10px;
  left: 0;
  width: 0%;
  z-index: 2;
}

.section {
  position: relative;
  height: auto;
}

.logo {
  top: 10px;
  width: 100px;
}

.nav-container {
  height: 60px;
}

.nav-item {
  margin: 0 10px;
  font-size: 14px;
}

.nav-item::before {
  bottom: -1px;
  height: 1px;
}
.hamburger {
  position: absolute;
  top: 10px;
  left: 295px; /* Adjust left position accordingly */
  z-index: 999;
}
