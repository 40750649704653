/* Reset some default styles */
body {
  margin: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

/* General container styles */
.container-m {
  padding: 0px;
  box-sizing: border-box;
  background-color: transparent;
}

/* Sections */
.section-m {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998; /* Adjust z-index to be behind the nav bar */
  background-color: transparent;
}

.intro-m {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: transparent;
  z-index: 100;
}

/* Services Section */
.section-our-services-m {
  width: 100%;
  height: 100vh; /* Cover the whole viewport height */
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 100;
}

.get-started-m {
  width: 100vw;
  position: absolute;
  background-color: #000;
  min-height: 100vh;
  z-index: 100;
}

/* Logo */
/* Add styles to align hamburger-container and logo-container-m horizontally */
.hamburger-container {
  display: flex;
  align-items: center;
  top: 35px; /* Adjust the top position as needed */
}

/* Apply flexbox to the logo-container-m as well */
.logo-container-m {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
  width: auto;
  z-index: 998;
}

/* Add cursor pointer when hovering over the logo */
.logo-m {
  width: 100%; /* Make the logo responsive */
  max-width: 120px; /* Adjust the maximum width as needed */
  cursor: pointer; /* Set the cursor to pointer on hover */
  animation: pulse 1.5s infinite alternate; /* added line */
}

/* New keyframes for pulsing animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.05);
    opacity: 1;
  }
}

/* If logo is clicked, stop animation */
.logo-m.clickable {
  animation: none;
}

/* Navbar */
.nav-container-m {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center; /* Vertically center-align items */
  height: 80px;
  z-index: 999;
}

/* Navbar items */
.nav-item-m {
  margin: 10px 15px; /* Reduced margin for closer spacing */
  cursor: pointer;
  color: white;
  position: relative;
  transition: color 0.3s ease; /* Smooth transition animation for text color */
  background-color: transparent;
}

/* Styles for non-active sections when hovered */
/* Existing styles for hover state */
/* Existing styles for hover state */
.nav-item-m:hover,
.nav-item-m.active-m {
  /* Adding active-m class here */
  color: #5bc0de;
}

.nav-item-m:hover::before,
.nav-item-m.active-m::before {
  /* Adding active-m class here */
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    45deg,
    #8a4fff,
    #8a4fff,
    #5bc0de,
    #5bc0de,
    #8a4fff,
    #8a4fff
  );
  background-size: 300% 300%;
  animation: gradientAnimation 3s linear infinite;
}

/* Existing styles for active state, remove conflicting lines */
.nav-item-m.active-m {
  position: relative;
  background-color: transparent;
}

/* Keyframes animation for the gradient pattern */
@keyframes gradientAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 300% 300%;
  }
}
.nav-items-container-m {
  position: absolute;
  top: 80px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
  width: auto;
  z-index: 998; /* Ensure it's behind the logo */
  flex-direction: column; /* Stack items in a column format */
  background: linear-gradient(
    45deg,
    #50f2ee,
    #9c0c5a,
    #2c2495
  ); /* Gradient background */
  border-radius: 5px; /* Add some border-radius for style */
  padding: 10px; /* Add padding to the container */
  transition: opacity 0.3s ease; /* Add a fade-in/fade-out transition effect */
}
