/* Use Montserrat font for all text */
body {
  font-family: "Montserrat", sans-serif;
}

/* Rest of your existing CSS with Montserrat font and reduced font-weight */
.hamburger-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #a700c9 0%, #4b0082 50%, #0808a8 100%);
  opacity: 1;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(110%) contrast(120%);
}

.option {
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-size: 2em;
  text-shadow:
    0 0 5px #ff00ff,
    0 0 10px #0000ff;
  font-weight: 400; /* Adjust the font weight as needed */
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 50%;
}

.option {
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-size: 2em;
  position: relative; /* Add relative positioning for pseudo-elements */
}

.option::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: white;
  transition: width 0.3s;
}

.option:hover::after {
  width: 100%;
}
