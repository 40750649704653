/* Set the black background for the entire screen */
body {
  margin: 0;
  padding: 0;
  background-color: black;
}

/* Customize the hamburger icon's appearance here */
.hamburger-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.hamburger-icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.hamburger-line {
  width: 100%;
  height: 4px;
  background-color: white; /* Set the hamburger items to white */
  border-radius: 2px;
  transition: transform 0.3s ease-in-out;
  z-index: 19999;
}

.hamburger-icon.open .hamburger-line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger-icon.open .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open .hamburger-line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}
