body-Mob {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.right-side-Mob {
  position: relative;
  width: 100%;
  min-height: 100vh; /* change to min-height */
}

/* Add this CSS to your MobileGetStarted component's CSS file */

/* Video Background */
.video-background-Mob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the video behind other elements */
  object-fit: cover; /* Maintain aspect ratio and cover the entire viewport */
  pointer-events: none; /* Prevent interaction with the video */
}

.form-container-gs-Mob {
  position: fixed;
  width: 90%;
  color: white;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  z-index: 2;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Center both horizontally and vertically */
  text-align: center; /* Center-align the text */
}

.send-button-Mob {
  background: deepskyblue;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 5px;
}

/* Rest of your CSS for input fields, buttons, etc. can remain the same */

.input-field-gs-Mob input,
.input-field-gs-Mob select,
.input-field-gs-Mob textarea {
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border-bottom: 2px solid linear-gradient(to right, purple, deepskyblue);
  transition: border-color 0.3s ease;
}

.input-field-gs-Mob input:focus,
.input-field-gs-Mob select:focus,
.input-field-gs-Mob textarea:focus {
  border-bottom: 2px solid deepskyblue;
}

.input-field-gs-Mob textarea {
  resize: none;
  height: 100px;
}

.input-field-gs-Mob label {
  color: #fff; /* Label text color */
  font-size: 16px; /* Label font size */
  margin-bottom: 5px; /* Spacing between label and input field */
}

.send-button-Mob {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-size: 16px;
}

.send-button-Mob:hover {
  background: rgba(255, 255, 255, 0.2);
}

.options-container-gs-Mob {
  margin-top: 0px;
  background-color: transparent;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.options-label-gs-Mob {
  font-size: 18px;
  margin-bottom: 5px;
  color: #00aaff;
}

.options-boxes-gs-Mob {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-box-gs-Mob {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  margin-bottom: 15px;
}

.option-box-gs-Mob:hover {
  background-color: #00aaff;
}

.selected-gs-Mob {
  background-color: #007bff;
}

.information-container-gs-Mob {
  position: absolute;
  width: calc(
    100% - 60px
  ); /* 100% width of the parent minus left and right padding */
  top: 50%;
  transform: translateY(-50%);
}
.info-box-gs-Mob {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px; /* Reduced vertical padding, kept horizontal the same */
  max-height: 700px; /* Set a maximum height */
  overflow-y: auto; /* Add scrolling if content overflows */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Take up the full width of the information-container-gs */
  /* Add these properties to ensure the box stays within the left container */
  position: absolute;
  left: -15%;
  top: 50%;
  transform: translateY(-50%);
}

.description-container-gs-Mob {
  display: flex;
  flex-direction: column;
}

.info-button-gs-Mob {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: deepskyblue;
  border: 1px solid deepskyblue;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  position: relative;
}

.info-button-gs-Mob:hover {
  background-color: deepskyblue;
  color: white;
  border-color: white;
}

.info-button-gs-Mob::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  bottom: 30px;
  width: max-content;
  white-space: nowrap;
}

.info-button-gs-Mob:hover::after {
  opacity: 1;
  visibility: visible;
}
