@media (max-width: 768px) {
  /* Global Styles */

  /* Intro Container */
  .mobile-intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    transition:
      opacity 0.6s ease,
      transform 0.6s ease;
    background-color: black;
  }
  /* Video Background */
  #mobile-intro-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
    overflow: hidden;
    opacity: 0.99;
  }

  /* Image Background */
  #mobile-myImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the whole screen */
    z-index: 3; /* Increased z-index to cover everything */
    opacity: 1;
  }

  /* Sentence styles */
  .mobile-sentence {
    color: white;
    opacity: 0;
    font-family: "Montserrat", sans-serif;
    width: 90%;
    text-align: center;
    position: absolute; /* Absolute positioning */
    top: 40%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Necessary for true centering */

    z-index: 10;
  }

  .mobile-sentence.mobile-visible {
    opacity: 1;
    color: white;
    font-size: 1.3em;
    font-weight: 400;
    z-index: 10;
  }

  /* Specialized Areas */
  /* Specialized Areas */
  .mobile-specialized-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    z-index: 10;
  }

  .mobile-specialized-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5em 0;
  }

  .mobile-specialized-item {
    display: flex;
    align-items: center; /* Center items horizontally */
    margin: 0.5em 0;
  }

  .mobile-specialized-logo {
    max-width: 7.5vw;
    height: auto;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
  }

  .mobile-specialized-label {
    font-size: 0.8em;
    text-align: left;
    margin-top: 0;
    /* Center horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-specialized-items {
    display: flex;
    flex-direction: column;

    gap: 3em;
  }

  .mobile-mouse-field-container {
    z-index: 10000;
    color: white;
  }
  .mobile-mouse-field {
    z-index: 10000;
    color: white;
  }
  .mobile-mouse {
    z-index: 10000;
    color: white;
  }
}
