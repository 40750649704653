/* Main container for services */
.services-container-mobile {
  font-family: "Montserrat", sans-serif;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  background-color: #9c0c5a;
  color: transparent;
}

/* Style for SentenceRef section */
.service.main-section-mobile {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 0.5s ease;
  background-color: #9c0c5a;
  animation: ultraSmoothColorChange 20s infinite;
}

/* Keyframes for ultra-smooth color transition */
@keyframes ultraSmoothColorChange {
  0%,
  100% {
    background-color: #9c0c5a;
  }
  12.5% {
    background-color: #884976;
  }
  25% {
    background-color: #2c2495;
  }
  37.5% {
    background-color: #66347e;
  }
  50%,
  50% {
    background-color: #9c0c5a;
  }
  62.5% {
    background-color: #66347e;
  }
  75% {
    background-color: #2c2495;
  }
  87.5% {
    background-color: #884976;
  }
}

.service.main-section-mobile.active {
  top: 0;
  color: white !important;
}
.sentence-ref {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

/* Common style for Consulting and Technology sections */
.sub-section-mobile {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30%; /* Adjust bottom margin for content spacing */
  width: 100%;
  height: 100%;
  transition:
    top 0.5s ease,
    opacity 0.5s ease; /* Added opacity transition */
  text-align: center;
  color: white;
  background: linear-gradient(to bottom, #8b00b3, #00009c);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0; /* Initially hidden */
}

/* Technology specific */

/* Consulting and Technology Headings */
.consulting-heading-mobile,
.technology-heading-mobile {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  color: #50f2ee;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-cons-container,
.mobile-tech-container {
  margin-top: 10px; /* 10px distance from the heading */
}

/* Consulting and Technology Descriptions */
.description.consulting-description-mobile,
.description.technology-description-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

/* Learn More Buttons */
.learn-more-button-mobile {
  font-family: "Montserrat", sans-serif;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

/* Mouse Field styles */
.mouse-field-container-mobile {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  top: 20px;
  bottom: 20px; /* Adjust the bottom margin as needed */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.mouse-field-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mouse-mobile {
  width: 30px;
  height: 60px;
  border: 2px solid #f1f1f1;
  border-radius: 30px;
  position: relative;
  bottom: 30px;
}

.mouse-mobile::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #9200a8;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
}

/* title box container for Consulting and Technology sections */
.title-box-mobile {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

/* Container for MobileTechD component */
.mobile-tech-container {
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 10px 0;
}

.mouse-field-container-s {
  /* Your existing styles */
  padding-top: 20px; /* Add top padding. Adjust the value as needed */
}
