/* TechnologyDetails.css */
.technology-details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 800px;
  background-color: #8a4fff; /* Updated background color */
  animation: ultraSmoothColorChange 20s infinite; /* 20-second long animation for smoother transitions */


  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Keyframes for ultra-smooth color transition with sharper colors */
@keyframes ultraSmoothColorChange {
  0%,
  100% {
    background-color: #8a4fff; /* Updated background color */
  }
  12.5% {
    background-color: #5bc0de; /* Updated background color */
  }
  25% {
    background-color: #f053bb; /* Updated background color */
  }
  37.5% {
    background-color: #ff8a00; /* Updated background color */
  }
  50% {
    background-color: #00baff; /* Updated background color */
  }
  62.5% {
    background-color: #3f49e8; /* Updated background color */
  }
  75% {
    background-color: #884976; /* Updated background color */
  }
  87.5% {
    background-color: #2c2495; /* Updated background color */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #ff6262;
}

.service-content {
  text-align: center;
  margin-bottom: 20px;
}

/* Service Title and Icon */
.service-title {
  font-size: 30px;
  font-family: "Montserrat", sans-serif; /* Existing Montserrat font */
  color: #50f2ee;
  font-weight: 400; /* Reduced thickness */
  text-align: center;
  position: absolute;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-title h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center; /* Make sure the text within h2 is centered */
}


.service-description {
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.8;
  max-width: 100%;
}

.carousel-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.carousel-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
  transition:
    color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  color: #03e9f4; /* Neon Blue */
  filter: url(#glow);
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin: 10px 0;
}

.progress-bar-fill {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  transition: width 0.5s ease;
}

.get-started-button {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  background-color: #50f2ee; /* Updated background color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 15px 30px; /* Increased padding for a larger button */
  font-size: 18px; /* Slightly increased font size */
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease,
    color 0.3s ease;
  margin-top: 20px; /* Added margin to create space */
}

.get-started-button:hover {
  background-color: #2c2495; /* Background color on hover */
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color on hover */
}
/* Updated SVG Filter for Enhanced Glow */

/* Updated Carousel Dot Style */
/* Defining variables for colors for easy maintenance */
:root {
  --primary-color: #3f49e8;
  --secondary-color: #f053bb;
  --glow-color: #03e9f4;
}

/* Updated Carousel Dot Style */
.carousel-dot {
  width: 16px;
  height: 16px;
  background: var(--primary-color); /* Using the primary color as background */
  border: none; /* Using the secondary color for the border */
  border-radius: 50%;
  display: inline-block;
  margin: 0 12px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0); /* Initial shadow set to transparent */
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.carousel-dot:hover {
  box-shadow:
    0 0 10px var(--glow-color),
    0 0 20px var(--glow-color); /* Neon glow on hover */
}

.carousel-dot.active {
  animation: pulsate 1.5s infinite alternate; /* Pulsing animation for active dot */
  border: none; /* Removing the border for the active dot */
  box-shadow:
    0 0 10px var(--glow-color),
    0 0 20px var(--glow-color); /* Glow for the active dot */
}

/* Pulsing animation for the active dot */
@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow:
      0 0 5px var(--glow-color),
      0 0 10px var(--glow-color);
  }
  100% {
    transform: scale(1.2);
    box-shadow:
      0 0 15px var(--glow-color),
      0 0 20px var(--glow-color),
      0 0 25px var(--glow-color),
      0 0 35px var(--glow-color);
  }
}
