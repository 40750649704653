/* Transition.css */

.transition-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 999999;
  transform: translateY(100%);
}

.transition-gradient-purple {
  flex: 1;
  background: linear-gradient(to right, #4b0082, #800080);
    z-index: 999999;

}

.transition-gradient-blue {
  flex: 1;
  background: linear-gradient(to right, #0000ff, #000080);
    z-index: 999999;

}
