/* TechnologyDetails.css */
.technology-details-popup-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 800px;
  color: #fff;
  padding: 20px;
  padding-left: 15px; /* Added padding to the left */
  padding-right: 15px; /* Added padding to the right */
  padding-top: 30px; /* Adjust this value based on your needs */
  padding-bottom: 30px; /* Adjust this value based on your needs */
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-content-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  text-align: center;
  margin-bottom: 20px;
}

.service-title-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  display: flex;
  font-weight: 400; /* Reduced thickness */
  justify-content: center;
  align-items: center;
}

.service-title-mob h2 {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  font-size: 24px;
  margin-right: 10px; /* Added margin to space out from icons */
  text-align: center;
}

@media (max-width: 992px) {
  .technology-details-popup-mob {
    width: 90%;
  }

  .service-title-mob h2,
  .service-description-mob,
  .get-started-button-mob {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .technology-details-popup-mob {
    width: 100%;
  }

  .service-title-mob h2,
  .service-description-mob,
  .get-started-button-mob {
    font-size: 16px;
  }
}

.service-description-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.8;
  max-width: 100%;
}

.carousel-nav-mob {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  z-index: 10;
}

.carousel-button-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  filter: url(#glow-mob);
  margin: 0 20px;
  margin-bottom: 10px;
}

.prev-button {
  transform: rotate(180deg);
}

.progress-bar-container-mob {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  margin: 10px 0;
}

.progress-bar-fill-mob {
  height: 100%;
  background-color: #fff;
  border-radius: 0;
  transition: width 0.5s ease;
}

.get-started-button-mob {
  font-family: "Montserrat", sans-serif; /* Added Montserrat font */
  background-color: #50f2ee; /* Updated background color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 15px 30px; /* Increased padding for a larger button */
  font-size: 18px; /* Slightly increased font size */
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease,
    color 0.3s ease;
  margin-top: 20px; /* Added margin to create space */
}

.get-started-button-mob:hover {
  background-color: #2c2495; /* Background color on hover */
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color on hover */
}

:root {
  --primary-color-mob: #2c2495;
  --secondary-color-mob: #9c0c5a;
  --glow-color-mob: #50f2ee;
}

.carousel-dot-mob {
  width: 16px;
  height: 16px;
  background: var(--glow-color-mob);
  border: none;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.carousel-dot-mob:hover {
  box-shadow:
    0 0 10px var(--glow-color-mob),
    0 0 20px var(--glow-color-mob);
}

.carousel-dot-mob.active-mob {
  animation: pulsate-mob 1.5s infinite alternate;
  border: none;
  box-shadow:
    0 0 10px var(--glow-color-mob),
    0 0 20px var(--glow-color-mob);
}

@keyframes pulsate-mob {
  0% {
    transform: scale(1);
    box-shadow:
      0 0 5px var(--glow-color-mob),
      0 0 10px var(--glow-color-mob);
  }
  100% {
    transform: scale(1.2);
    box-shadow:
      0 0 15px var(--glow-color-mob),
      0 0 20px var(--glow-color-mob),
      0 0 25px var(--glow-color-mob),
      0 0 35px var(--glow-color-mob);
  }
}

/* MobileTechD.css */
.service-title-container-mob {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally on the x-axis */
  margin-right: 10px; /* Add margin to create space between icon and title */
}

.service-title-container-mob svg {
  margin-right: 10px; /* Adjust the margin as needed */
  font-size: 24px; /* Adjust the font-size to increase the SVG size */
}
